import Chart from 'stimulus-chartjs'

const formattedDate = date => {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000
  const correctDate = new Date(date.getTime() + userTimezoneOffset)
  return `${correctDate.toLocaleString('default', { month: 'short' })} ${correctDate.getDate()}, ${correctDate.getFullYear()}`
}

class PulseChartController extends Chart {
  static values = {
    data: Object,
    options: Object,
    type: { type: String, default: 'line' },
    submissions: Array,
    colorName: String
  }

  connect() {
    super.connect()
    this._setLineColor()
  }

  _setLineColor() {
    const lineColor = getComputedStyle(document.documentElement).getPropertyValue(this.colorNameValue)
    this.chart.data.datasets[0].borderColor = lineColor
    this.chart.data.datasets[0].backgroundColor = lineColor
    this.chart.update()
  }

  get defaultOptions() {
    return {
      maintainAspectRatio: false,
      scales: {
        y: {
          min: 0,
          max: 5,
          ticks: {
            maxTicksLimit: 6,
          },
        },
        x: {
          ticks: {
            maxTicksLimit: 12,
            callback: this._xTickLabel
          }
        }
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          displayColors: false,
          callbacks: {
            title: this._tooltipTitle,
            afterBody: this._tooltipAfterBody.bind(this)
          }
        }
      }
    }
  }

  _xTickLabel(value) { return formattedDate(new Date(this.getLabelForValue(value))) }

  _tooltipTitle(tooltipItem) {
    const pulseDate = tooltipItem[0].label

    const startDate = new Date(pulseDate)
    startDate.setDate(startDate.getDate() - 6)

    const endDate = new Date(pulseDate)

    return `${formattedDate(startDate)} - ${formattedDate(endDate)}`
  }

  _tooltipAfterBody(tooltipItem) {
    const submissionCount = this.submissionsValue[tooltipItem[0].dataIndex]
    if (!submissionCount) return

    return `Submissions: ${submissionCount}`
  }
}

export default PulseChartController
