import PropTypes from 'prop-types'
import React from 'react'
import classNames from 'classnames'

export default class ReportSelection extends React.PureComponent {
  static propTypes = {
    report: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

  _toggleDropdown(event) {
    event.preventDefault()
    this.setState({ open: !this.state.open })
  }

  render() {
    const { name, start_date, end_date, range_string } = this.props.report
    const query_string = new URL(document.location).searchParams

    return (
      <div className="dropdown dropdown--left">
        <a className={classNames('dropdown-text', { 'dropdown-text--open': this.state.open })}
           href="#"
           onClick={this._toggleDropdown.bind(this)}>
          {name}
        </a>
        <ul className={classNames('dropdown-content', { 'dropdown-content--visible': this.state.open })}>
          <li>
            <a href={`/billable_report?${query_string}`}>Billable Hours Report</a>
          </li>
          <li>
            <a href={`/booked_report?${query_string}`}>Prediction Report</a>
          </li>
          <li>
            <a href={`/pulse_report?${query_string}`}>Pulse Report</a>
          </li>
        </ul>
      </div>
    )
  }
}
