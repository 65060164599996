import { Controller } from '@hotwired/stimulus'

class DateSelectController extends Controller {
  static targets = [
    'startDateInput',
    'endDateInput',
    'allDayCheckbox',
    'timeFields',
    'endTimeInput'
  ]

  connect() {
    super.connect()
    this._refreshAllDayCheckboxStatus()
    this.timeFieldsTarget.classList.toggle('hidden', !this.endTimeInputTarget.value)
    this.allDayCheckboxTarget.checked = !this.endTimeInputTarget.value
  }

  startDateSelected(event) {
    if (this.endDateInputTarget.value > event.target.value) return this._refreshAllDayCheckboxStatus()

    this.endDateInputTarget.value = event.target.value
    this.endDateInputTarget.min = event.target.value
    this._callOnChange(this.endDateInputTarget)
  }

  startTimeSelected(event) {
    if (this.endTimeInputTarget.value > event.target.value) return

    this.endTimeInputTarget.value = event.target.value
    this.endTimeInputTarget.min = event.target.value
    this._callOnChange(this.endTimeInputTarget)
  }

  endDateSelected() {
    this._refreshAllDayCheckboxStatus()
  }

  toggleTimeFields(event) {
    this.timeFieldsTarget.classList.toggle('hidden', event.target.checked)
  }

  _refreshAllDayCheckboxStatus() {
    const startDate = this.startDateInputTarget.value
    const endDate = this.endDateInputTarget.value
    const datesAreOnDifferentDays = Boolean(startDate) && Boolean(endDate) && startDate !== endDate

    if (datesAreOnDifferentDays) {
      this.allDayCheckboxTarget.disabled = true
      this.allDayCheckboxTarget.checked = true
      this._callOnChange(this.allDayCheckboxTarget)
    } else {
      this.allDayCheckboxTarget.disabled = false
    }
  }

  _callOnChange(element) {
    element.dispatchEvent(new Event('change'))
  }
}

export default DateSelectController
