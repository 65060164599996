import { Controller } from '@hotwired/stimulus'
import { setFsaExpenses, getFsaExpenses, showFlashMessage } from '../helpers/fsaExpenses'

class FsaExpenses extends Controller {
  static targets = ['expenses', 'gift', 'contribution', 'monthlyTotal', 'match', 'total', 'maxWarning', 'clearAll']
  static values = {
    maxContribution: Number
  }

  connect() {
    this._buildSummary()
    this._buildExpensesTableRows()
  }

  _buildSummary() {
    this.giftTarget.textContent = this._formatMoney(this._roleModelGift())
    this.matchTarget.textContent = this._formatMoney(this._match())
    this.totalTarget.textContent = this._formatMoney(this._total())
    this.contributionTargets.forEach((target) => (target.textContent = this._formatMoney(this._totalContributionCost())))
    this.monthlyTotalTarget.textContent = this._formatMoney(this._totalContributionCost() / 12)
    this._totalContributionCost() > this.maxContributionValue ? this.maxWarningTarget.classList.remove('hidden') : this.maxWarningTarget.classList.add('hidden')
  }

  _buildExpensesTableRows() {
    const expenses = getFsaExpenses()
    expenses.length > 0 ? this.clearAllTarget.classList.remove('hidden') : this.clearAllTarget.classList.add('hidden')
    const rows = []

    expenses.forEach((expense) => {
      const row = `
        <tr>
          <td>${expense.description}</td>
          <td>${expense.price}</td>
          <td>${expense.frequency}</td>
          <td>${expense.timeframe}</td>
          <td>${this._formatMoney(this._totalItemCost(expense.price, expense.frequency, expense.timeframe))}</td>
          <td>
            ${
              expense.link
                ? `
              <a href=${expense.link} class="btn btn--primary btn--small" target="_blank" rel="noopener">
                Buy
              </a>`
                : ''
            }
          </td>
          ${this._buildActions(expense.key).outerHTML}
        </tr>
      `
      rows.push(row)
    })
    this.expensesTarget.innerHTML = rows.join('')
  }

  _buildActions(key, editAction = true, deleteAction = true) {
    const actions = document.createElement('td')
    actions.classList.add('actions')
    actions.setAttribute('key', key)

    if (editAction) {
      const editButton = `
        <a href="/fsa_expenses/new?key=${key}" class="btn--link margin-right-xs" data-remote="true" data-modal="true" data-turbo="true">
          Edit
        </a>
      `
      actions.innerHTML += editButton
    }

    if (deleteAction) {
      const deleteButton = `<button class="btn--link" data-action="click->fsa-expenses#deleteRow" key=${key}>Delete</button>`
      actions.innerHTML += deleteButton
    }

    return actions
  }

  deleteRow(event) {
    if (!confirm('Are you sure?')) return

    const key = event.target.attributes.key.textContent
    let almanacData = getFsaExpenses()
    almanacData = almanacData.filter((row) => row.key !== key)
    setFsaExpenses(almanacData)

    showFlashMessage('Successfully deleted expense', 'success')
    this._buildSummary()
    this._buildExpensesTableRows()
  }

  clearAll() {
    if (!confirm('Are you sure?')) return

    setFsaExpenses([])
    showFlashMessage('Successfully cleared all expenses', 'success')
    this._buildSummary()
    this._buildExpensesTableRows()
  }

  _totalItemCost(price, frequency, timeframe) {
    const timeframeValues = { day: 365, week: 52, month: 12, quarter: 4, year: 1 }
    return price * frequency * timeframeValues[timeframe]
  }

  _total() {
    const expenses = getFsaExpenses()
    return expenses.reduce((total, expense) => (total += this._totalItemCost(expense.price, expense.frequency, expense.timeframe)), 0)
  }

  _match() {
    return Math.max(this._total() / 2 - this._roleModelGift(), 0)
  }

  _totalContributionCost() {
    return Math.max(this._total() - this._roleModelGift() - this._match(), 0)
  }

  _roleModelGift() {
    return 500
  }

  _formatMoney(value) {
    return `$${Number.isInteger(value) ? value : value.toFixed(2)}`
  }
}

export default FsaExpenses
